import { StaticQuery, graphql } from "gatsby"
import React from "react"

import HeaderComponent from './header'

const HeaderAdma = ({ hasImageHeader, layout }) => {
  return <StaticQuery
    query={graphql`
      fragment NavigationPage on ContentfulPage {
        __typename
        id
        title
        slug
      }
      fragment NavigationItem on ContentfulNavigationItem {
        __typename
        id
        title
        icon {
          icon
        }
        link
        items {
          ... on ContentfulPage {
            __typename
            id
            title
            slug
          }
        }
      }
      {
        meta: contentfulNavigationZone(title: {eq: "Header Meta ADMA"}) {
          items {
            ...NavigationPage
            ...NavigationItem
          }
        }
        navigation: contentfulNavigationZone(title: {eq: "Header ADMA"}) {
          items {
            ...NavigationPage
            ...NavigationItem
          }
        }
        quick: contentfulNavigationZone(title: {eq: "Header Quick ADMA"}) {
          items {
            ...NavigationPage
            ...NavigationItem
          }
        }
        socials: contentfulNavigationZone(title: {eq: "Socials"}) {
          items {
            ...NavigationPage
            ...NavigationItem
          }
        }
      }
    `}
    render={data => (
      <HeaderComponent hasImageHeader={hasImageHeader} layout={layout} data={data} />
    )}
  />
}

export default HeaderAdma
