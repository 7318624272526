import * as styles from './renderer.module.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import gfm from 'remark-gfm'

class TextRenderer extends Component {
  render() {
    const { text } = this.props

    return (
      <div className={styles.text}>
        <ReactMarkdown children={text} plugins={[breaks, gfm]}/>
      </div>
    )
  }
}

TextRenderer.propTypes = {
  text: PropTypes.string.isRequired
}

TextRenderer.defaultProps = {
  text: ``
}

export default TextRenderer
