import * as styles from './overlay.module.scss'

import { Link } from "gatsby"
import PropTypes from 'prop-types'
import React from "react"
import { pathTo } from '../../routes'
import Divider from '../divider'

const HeaderOverlay = ({ navigation, socials }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div>
          {navigation.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                {(index + 1 === navigation.length &&
                  <div className={styles.divider}>
                    <Divider size="small" />
                  </div>
                )}
                <div className={styles.item}>
                  {((item.link || item.slug) &&
                    <Link to={pathTo(item)} className={styles.anchor} activeClassName={styles.active}>
                      {item.title}
                    </Link>
                  )}
                  {((!item.link && !item.slug) &&
                    <div className={styles.anchor}>
                      {item.title}
                    </div>
                  )}
                  {(item.items &&
                    <div className={styles.subnavigation}>
                      {item.items.map((subItem, index) => (
                        <Link to={pathTo(subItem)} key={subItem.id} className={styles.sub}>
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
          })}
        </div>
        <div className={styles.icons}>
          {socials.map(item => (
            <a
              key={item.id}
              href={item.link}
              className={styles.icon}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: item?.icon?.icon }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

HeaderOverlay.propTypes = {
  navigation: PropTypes.array.isRequired,
  socials: PropTypes.array.isRequired
}

HeaderOverlay.defaultProps = {
  navigation: [],
  socials: []
}

export default HeaderOverlay
