import * as styles from './index.module.scss';

import CookieBanner from '../cookie-banner'
import Footer from "../footer";
import Header from "../header";
import PropTypes from "prop-types";
import React from "react";

const Layout = ({ children, hasImageHeader, layout }) => {
  const frontPage = hasImageHeader === null ? false : hasImageHeader
  const theLayout = layout === null ? 'dmap' : layout
  return (
    <>
      <Header hasImageHeader={frontPage} layout={theLayout}/>
      <main className={styles.main} data-has-image-header={hasImageHeader}>{children}</main>
      <Footer/>
      <CookieBanner/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string.isRequired,
  hasImageHeader: PropTypes.bool
}

Layout.defaultProps = {
  hasImageHeader: false,
  layout: 'dmap'
}

export default Layout;
