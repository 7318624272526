import React from "react";

import * as styles from './index.module.scss';
import dividerLarge from '../../assets/images/divider-large.svg';
import dividerSmall from '../../assets/images/divider-small.svg';
import dividerMobile from '../../assets/images/divider-mobile.svg';

const Divider = ({ size }) => {
  const divider = size === 'large' ? dividerLarge : dividerSmall;

  return (
    <div className={styles.divider} data-size={size}>
      <img className={styles.desktop} src={divider} alt='' title=''/>
      <img className={styles.mobile} src={dividerMobile} alt='' title=''/>
    </div>
  )
};

Divider.defaultProps = {
  size: 'large'
}

export default Divider;
