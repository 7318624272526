import { navigate } from 'gatsby';

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () => isBrowser() && window.localStorage.getItem("userAuthenticationToken");
export const setUserToken = token => window.localStorage.setItem("userAuthenticationToken", token);

export const isLoggedIn = () => {
  return getUser();
}

export const logout = () => {
  isBrowser() && window.localStorage.removeItem("userAuthenticationToken");
  navigate('/presse');
}
