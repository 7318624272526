function pathTo(resource) {
  switch (resource.__typename) {
    case 'ContentfulChronic':
      return `/chronik/${resource.title}`;
    case 'ContentfulJudge':
      return `/jury/${resource.slug}`;
    case 'ContentfulNavigationItem':
      return resource.link;
    case 'ContentfulPost':
      return `/newsroom/${resource.slug}`;
    default:
      return resource.slug === '/' ? '/' : `/${resource.slug}`;
  }
}

module.exports.pathTo = pathTo;
