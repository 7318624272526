import * as styles from './index.module.scss'

import { Link, StaticQuery, graphql } from "gatsby"

import Container from '../container'
import Divider from '../divider'
// import FooterInstagram from './instagram'
import React from "react"
import { pathTo } from '../../routes'

const Footer = () => {
  // <FooterInstagram/>
  return (
    <>
      <Container>
        <Divider />
      </Container>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.wrapper}>
            <StaticQuery
              query={graphql`
                {
                  navigation: contentfulNavigationZone(title: {eq: "Footer"}) {
                    items {
                      ... on ContentfulPage {
                        __typename
                        id
                        title
                        slug
                      }
                      ... on ContentfulNavigationItem {
                        __typename
                        id
                        title
                        link
                        icon {
                          icon
                        }
                      }
                    }
                  }
                  socials: contentfulNavigationZone(title: {eq: "Socials"}) {
                    items {
                      ... on ContentfulNavigationItem {
                        __typename
                        id
                        title
                        link
                        icon {
                          icon
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  <div className={styles.navigation}>
                    {[...data.navigation.items].map(item => (
                      <Link key={item.id} to={pathTo(item)} className={styles.item}>
                        {item.title}
                      </Link>
                    ))}
                  </div>
                  <div className={styles.icons}>
                    {[...data.socials.items].map(item => {
                      console.log({ item })
                      return (
                        <a
                          key={item.id}
                          href={item.link}
                          className={styles.icon}
                          target="_blank"
                          rel="noopener noreferrer"
                          dangerouslySetInnerHTML={{ __html: item?.icon?.icon }}
                        />
                      )
                    })}
                  </div>
                </>
              )}
            />
            <div className={styles.copyright}>&copy; Deutscher Musikautor*innenpreis</div>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer


// instagramPosts: allInstagramContent(filter: {caption: {regex: "/#mondayhoops/"}}, limit: 6) {
//   totalCount
//   edges {
//     node {
//       username
//       media_url
//     }
//   }
// }
