import React from "react";

import HeaderAdma from './adma';
import HeaderDmap from './dmap';

const Header = ({ hasImageHeader, layout }) => {
  if (layout === 'dmap') {
    return <HeaderDmap hasImageHeader={hasImageHeader} layout={layout}/>;
  } else {
    return <HeaderAdma hasImageHeader={hasImageHeader} layout={layout}/>;
  }
}

export default Header;
