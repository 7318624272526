// extracted by mini-css-extract-plugin
export var header = "header-module--header--4dQNe";
export var top = "header-module--top--wMUWA";
export var bottom = "header-module--bottom--dpt9t";
export var icon = "header-module--icon--S7vcO";
export var item = "header-module--item--CctBK";
export var toggle = "header-module--toggle--hna1r";
export var bar = "header-module--bar--ytPEJ";
export var logo = "header-module--logo--WAoX3";
export var wrapper = "header-module--wrapper--CU6iV";
export var toggleWrapper = "header-module--toggleWrapper--cEdN4";
export var desktop = "header-module--desktop--giPgf";
export var mobile = "header-module--mobile--c64Np";
export var quick = "header-module--quick--9EtnT";
export var socials = "header-module--socials--amdfj";
export var itemActive = "header-module--itemActive--zNxqq";
export var fadein = "header-module--fadein--cuq2R";