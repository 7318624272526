import { Link } from "gatsby"
import PropTypes from 'prop-types'
import React from "react"

import { pathTo } from '../../routes'
import { isLoggedIn, logout } from '../../services/auth'
import * as styles from './meta.module.scss'

const HeaderMeta = ({ items, hasImageHeader }) => {
  return (
    <div className={styles.meta}>
      <div className={'ef'}>
        {items.map(item => (
          <Link key={item.id} to={pathTo(item)} className={styles.item}>
            {item.title}
          </Link>
        ))}
        {(isLoggedIn() &&
          <div className={styles.item} onClick={() => logout()}>
            Logout
          </div>
        )}
      </div>
    </div>
  )
}

HeaderMeta.propTypes = {
  items: PropTypes.array.isRequired,
  hasImageHeader: PropTypes.bool.isRequired
}

HeaderMeta.defaultProps = {
  items: [],
  hasImageHeader: false
}

export default HeaderMeta
