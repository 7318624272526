import React from "react";
import { StaticQuery, graphql } from "gatsby";

import HeaderComponent from './header';

const HeaderDmap = ({ hasImageHeader, layout }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          meta: contentfulNavigationZone(title: {eq: "Header Meta"}) {
            items {
              ...NavigationPage
              ...NavigationItem
            }
          }
          navigation: contentfulNavigationZone(title: {eq: "Header"}) {
            items {
              ...NavigationPage
              ...NavigationItem
            }
          }
          socials: contentfulNavigationZone(title: {eq: "Socials"}) {
            items {
              ...NavigationPage
              ...NavigationItem
            }
          }
        }
      `}
      render={data => <HeaderComponent hasImageHeader={hasImageHeader} layout={layout} data={data}/>}
    />
  )
}

export default HeaderDmap;
