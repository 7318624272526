import * as styles from './header.module.scss'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { useEffect, useState } from "react"

import { Link } from "gatsby"
import PropTypes from 'prop-types'
import logoAdmaWhite from '../../assets/images/logo-adma-white.svg'
import logoAdma from '../../assets/images/logo-adma.svg'
import logoDmapMobileWhite from '../../assets/images/logo-dmap-mobile-white.svg'
import logoDmapMobile from '../../assets/images/logo-dmap-mobile.svg'
import logoDmapWhite from '../../assets/images/logo-dmap-white.svg'
import logoDmap from '../../assets/images/logo-dmap.svg'
import { pathTo } from '../../routes'
import Container from '../container'
import HeaderMeta from './meta'
import HeaderOverlay from './overlay'

const HeaderComponent = ({ data, hasImageHeader, layout }) => {
  const [forceWhite, setForceWhite] = useState(false)
  const [showNavigation, setShowNavigation] = useState(false)

  const logos = {
    'dmap': {
      'color': {
        'desktop': logoDmap,
        'mobile': logoDmapMobile
      },
      'white': {
        'desktop': logoDmapWhite,
        'mobile': logoDmapMobileWhite
      }
    },
    'adma': {
      'color': {
        'desktop': logoAdma,
        'mobile': logoAdma
      },
      'white': {
        'desktop': logoAdmaWhite,
        'mobile': logoAdmaWhite
      }
    }
  }

  const getLogo = (type = "dmap", color = 'color', variant = 'desktop') => {
    return logos[type][color][variant]
  }

  const logoColor = !hasImageHeader || forceWhite ? 'color' : 'white'

  const logoDesktop = getLogo(layout, logoColor, 'desktop')
  const logoMobile = getLogo(layout, logoColor, 'mobile')
  const homePath = layout === 'dmap' ? '/' : '/adma'

  const listener = e => {
    const top = document.body.getBoundingClientRect().top

    if (-top / .35 > window.innerHeight) {
      setForceWhite(true)
    } else {
      setForceWhite(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  })

  useEffect(() => {
    if (showNavigation) {
      disableBodyScroll(window)
    } else {
      enableBodyScroll(window)
    }
  }, [showNavigation])

  return (
    <header location="/" className={styles.header} data-has-image-header={hasImageHeader} data-force-white={forceWhite} data-layout={layout}>
      <div className={styles.top}>
        <Container>
          <HeaderMeta items={data.meta.items} hasImageHeader={hasImageHeader} />
        </Container>
      </div>
      {(showNavigation &&
        <HeaderOverlay navigation={data.navigation.items} socials={data.socials.items} />
      )}
      <div className={styles.bottom}>
        <Container>
          <div className={styles.wrapper}>
            <div className={styles.toggleWrapper}>
              <div className={styles.toggle} data-toggled={showNavigation} onClick={() => setShowNavigation(!showNavigation)}>
                <div className={styles.bar} />
                <div className={styles.bar} />
              </div>
            </div>
            <div className={styles.logo}>
              <Link to={homePath} className={styles.desktop}>
                <img src={logoDesktop} alt='Das Logo des Deutschen Musikautorinnenpreises' title='Logo Deutscher Musikautorinnenpreis' />
              </Link>
              <Link to='/' className={styles.mobile}>
                <img src={logoMobile} alt='Das Logo des Deutschen Musikautorinnenpreises' title='Logo Deutscher Musikautorinnenpreis' />
              </Link>
            </div>
            {(data.quick !== undefined &&
              <div className={styles.quick}>
                {[...data.quick.items].map(item => (
                  <Link key={item.id} to={pathTo(item)} className={styles.item} activeClassName={styles.itemActive}>
                    {item.title}
                  </Link>
                ))}
              </div>
            )}
            {(data.quick === undefined &&
              <div className={styles.socials}>
                {[...data.socials.items].map(item => (
                  <a
                    key={item.id}
                    href={item.link}
                    className={styles.icon}
                    target="_blank"
                    rel="noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: item?.icon?.icon }}
                  />
                ))}
              </div>
            )}
          </div>
        </Container>
      </div>
    </header>
  )
}

HeaderComponent.propTypes = {
  hasImageHeader: PropTypes.bool,
  layout: PropTypes.string
}

HeaderComponent.defaultProps = {
  hasImageHeader: false,
  layout: 'dmap'
}

export default HeaderComponent
